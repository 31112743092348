import React from "react"
import {Head, Layout} from '@common'

const NotFoundPage = ({pageContext}) => (
  <Layout >
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
